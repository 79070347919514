.main-footer {
  color: #43495e;
  background-color: #ffffff;
  padding-top: 1em;
  margin-top:5em;
  position: absolute;
  bottom: 80;
  width: 100%;
}

.low-volume-footer {
  color: #43495e;
  background-color: #ffffff;
  padding-top: 1em;
  margin-top:5em;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pricing-footer {
  color: #43495e;
  background-color: #ffffff;
  padding-top: 1em;
  position: relative;
  bottom: 0;
  width: 100%;
}
