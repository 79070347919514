#image_upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_container {
  max-height: 280px;
  width: 450px;
  display: block;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
}

.faq-title {
  font-family: 'glacialindifference-bold', Arial, sans-serif;
}

.row-title {
  font-family: 'glacialindifference-regular', Arial, sans-serif;
  font-weight:600!important;
  margin-left: 20px;
  font-size: 24px!important;
  text-align: left;
}

.row-content-text {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
  font-size: 20px!important;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.05);
}

.customSelect__value-container{
  min-height: 40px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.empty_textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  padding-top: 5px;
  font-family: 'glacialindifference-regular', Arial, sans-serif;
  font-weight:800!important;
  font-style:normal;
  font-size: 70px!important;
  color: #43495e!important;
}

.brand-registration {
  padding-top: 5px;
  font-family: 'glacialindifference-regular', Arial, sans-serif;
  font-weight:800!important;
  font-style:normal;
  font-size: 20px;
  color: #43495e!important;
}

.product_info_button {
  width: 12vw;
  height:12vw;
  padding: 10px 24px;
  border-radius: 8px;
  border: none;
  margin-right: 15px;
  margin-top: 3%;
  margin-bottom: 3%;
  vertical-align:bottom;
}

div.vertical-line{
  width: 3px;
  background-color: lightgrey;
  height: 100%;
  float: left;
}

#selected {
  box-shadow: 5px 5px 5px lightgrey;
}

#timeline_container p {
   display:inline-block;
}

.bg-gradient-traffic {
  background: #868686 !important;
  background: linear-gradient(45deg, #aeaeae 0%, #868686 100%) !important;
  border-color: #868686 !important;
}

.bg-gradient-primary {
  background: #1f1498 !important;
  background: linear-gradient(45deg, #321fdb 0%, #1f1498 100%) !important;
  border-color: #1f1498 !important;
}

.bg-gradient-info {
  background: #2982cc !important;
  background: linear-gradient(45deg, #39f 0%, #2982cc 100%) !important;
  border-color: #2982cc !important;
}

.bg-gradient-warning {
  background: #f6960b !important;
  background: linear-gradient(45deg, #f9b115 0%, #f6960b 100%) !important;
  border-color: #f6960b !important;
}

.bg-gradient-danger {
  background: #10BC10 !important;
  background: linear-gradient(45deg, #70d770 0%, #10BC10 100%) !important;
  border-color: #10BC10 !important;
}

.text-value-lg {
  text-align: left !important;
  font-size: 1.3125rem;
  font-weight:600;
}

@font-face {
  font-family: "glacialindifference-bold";
  src: url("./fonts/glacialindifference-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "glacialindifference-regular";
  src: url("./fonts/glacialindifference-regular-webfont.woff") format("woff");
}

@media screen and (min-width: 600px) {

  .img_container {
    width: 100%;
  }

  .img_storage {
    max-width: 100%
  }
}

@media screen and (max-width: 600px) {
  .img_container {
    width: 100%;
  }

  .img_storage {
    max-width: 100%;
  }
}

ul {
    list-style-type: none;
    padding: 0;
}

.img_preview {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.crop {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.image-fit-img {
  border-radius: 10px;
  border: 1px solid #555;
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  right: 0;
  top: 0;
  width: 100%;
}

.front {
  z-index: 10;
}

fieldset {
  margin: 20px;
  padding: 0 10px 10px;
  border: 1px solid #666;
  border-radius: 8px;
}

.dark-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
}

.home-fit-img {
  border-radius: 10px 10px 0px 0px;
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  right: 0;
  top: 0;
  width: 100%;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
}

.subtle-shadow {
  box-shadow: 0px 2px 6px 4px #ccc;
  -moz-box-shadow:  0px 2px 6px 4px #ccc;
  -webkit-box-shadow: 0px 2px 6px 4px #ccc;
  border-radius: 10px;
}

.search-background {
  background: url('assets/search_background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.gradient {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

.home-fit-no-shadow-img {
  border-radius: 10px 10px 0px 0px;
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  right: 0;
  top: 0;
  width: 100%;
}

.earnings-text {
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
}

body {
    overflow-x: hidden;
    width: 100%;
}

.pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

.pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #fbf4cd;
    color: #000;
    border-radius: 3px;
  }

.home-label {
  -moz-box-shadow: 10px 8px 5px #ccc;
  -webkit-box-shadow: 10px 8px 5px #ccc;
  box-shadow: 10px 8px 5px  #ccc;
}

.home-product-image {
  -moz-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-radius: 10px;
}

.avatar {
  border-radius: 130px;
}

.logo250 {
  height: 125px;
  width: 125px;
}

.img_storage {
  height: auto;
  width: auto;
  border-radius: 10px;
}

.img_icon {
  width: 25px;
  height: 25px;
}

.school_logo {
  object-fit: cover;
  border-radius: 18px;
  overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

h5 {
  font-family: 'glacialindifference-bold', Arial, sans-serif;
}

.color-nav {
  background-color: #43495e;
}

.button_empty {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.button_primary {
  height: 40px;
  border-radius: 20px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #fdad16;
  box-shadow: 5px 5px 5px lightgrey;
  font-weight: bold;
}

.button_disabled {
  height: 40px;
  border-radius: 20px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #cccccc;
  font-weight: bold;
}

.button_reserve {
  height: 40px;
  width: 100%;
  border-radius: 20px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #fdad16;
  box-shadow: 5px 5px 5px lightgrey;
  font-weight: bold;
}

.button_secondary {
  height: 40px;
  background-color: #ffffff00;
  font-weight: bold;
  padding: 5px 20px;
  color: #43495e;
  border: none;
  font-family: 'glacialindifference-bold', Arial, sans-serif;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button:disabled,
button[disabled]{
  color: #000000;
}

.profile_primary_link {
  height: 30px;
  border-radius: 20px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #43495e;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.home_onboard_customer {
  font-family: 'glacialindifference-bold', Arial, sans-serif;
  height: 50px;
  border-radius: 50px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #fdad16;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
}

.home_onboard_lister {
  font-family: 'glacialindifference-bold', Arial, sans-serif;
  height: 50px;
  border-radius: 50px!important;
  border-width: 1px;
  border-color: #e4e4e4;
  padding: 5px 20px;
  color: black;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
}

.home_onboard_customer:hover {
  background-color: #b17401;
}

.sign_up {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.sign_up:hover {
  color: #d0d1d7;
}

.home_onboard_lister:hover {
  background-color: #d7d7d7;
}

.profile_get_started {
  height: 30px;
  border-radius: 20px!important;
  padding: 5px 20px;
  color: white;
  border: none;
  background-color: #fdad16;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin-left: 44%;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
